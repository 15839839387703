<template lang="">
  <div>
    <!-- Start Filter -->
    <b-card v-if="showFilter">
      <h1>Filter</h1>
      <b-row>
        <b-col cols="12">
          <b-form-group
            label-cols-md="2"
            label="Status"
          >
            <b-form-select
              v-model="status"
              :options="[
                { value: null, text: 'Semua Status' },
                { value: 'request', text: 'Butuh Approval Vendor' },
                { value: 'plan', text: 'Belum Ditangani Bengkel' },
                { value: 'approved', text: 'Dijadwalkan' },
                { value: 'process', text: 'Ditangani Bengkel' },
                { value: 'done', text: 'Selesai Penanganan' },
                { value: 'document', text: 'Kelengkapan Dokumen' },
                { value: 'finish', text: 'Selesai Perawatan' },
                { value: 'reject', text: 'Ditolak' },
              ]"
              @input="changeSelectStatus"
            />
          </b-form-group>
          <b-form-group
            label-cols-md="2"
            label="Rutin / Non Rutin"
          >
            <b-form-select
              v-model="is_rutin"
              :options="[
                {value: null, text: 'Semua'},
                {value: true, text: 'Perawatan Rutin'},
                {value: false, text: 'Perawatan Non Rutin'},
              ]"
              @input="changeSelectStatus"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-button
            class="mr-1"
            variant="outline-secondary"
            type="button"
            @click.prevent="reset"
          >Reset</b-button>
          <b-button
            class="mr-1"
            variant="primary"
            type="button"
            @click.prevent="getFilter"
          >Filter</b-button>
        </b-col>
      </b-row>
    </b-card>
    <!-- End Of Filter -->
    <!-- dataurl filter status old = ?except_status=request' -->
    <b-card v-if="renderComp">
      <basetable
        ref="basetable"
        :dataurl="dataurl" 
        :fields="fields"
        :baseroute="baseroute"
        :title="title"
        :filter="filter"
        :custom_filter="custom_filter"
        :is-detail="isDetail"
        :tombol_hide="true"
        :is-add="true"
        :is-edit="false"
        :is-delete="false"
      >
        <template v-slot:buttonaddon>
          <b-button
            class="float-right"
            variant="info"
            @click="showFilter =!showFilter"
          >
            <feather-icon
              icon="FilterIcon"
            />
            Filter
          </b-button>
        </template>
        <template #cell(status)="data">
          <span
            class="badge " :class="dataStatus[getStatus(data.item)]?'bg-'+dataStatus[getStatus(data.item)].color:'bg-secondary'"
          >
            {{ dataStatus[getStatus(data.item)]?dataStatus[getStatus(data.item)].value:'undefined'}}
          </span>
        </template>
        <template #cell(is_rutin)="data">
          <span class="badge " :class="!data.item.issue?'bg-primary':'bg-success'">
            {{!data.item.issue?'Perawatan Rutin':'Perawatan Non Rutin'}}
          </span>
        </template>
        <template #cell(created_at)="data">
          {{data.item.created_at|moment('Y-MM-DD')}}
        </template>
        <template #cell(bill_type)="data">
          {{bill_type[data.item.bill_type]?bill_type[data.item.bill_type]:data.item.bill_type?data.item.bill_type:'-'}}
        </template>
        <template #cell(plan_km)="data">
          {{data.item.plan_km | numFormat}}
        </template>
        <template #cell(realisation_cost)="data">
          {{data.item.realisation_cost | numFormat}}
        </template>
        <template #cell(realisation_km)="data">
          {{data.item.realisation_km | numFormat}}
        </template>
      </basetable>
    </b-card>
  </div>
</template>
<script>
import basetable from '@/views/base/BaseTableV4.vue'
import { BCard,BButton,BFormGroup,BFormSelect,BRow,BCol } from 'bootstrap-vue'

export default {
  components: {
    basetable,
    BCard,
    BButton,
    BFormGroup,

    BFormSelect,
    BRow,
    BCol,
  },
  data() {
    return {
      dataurl:"/maintenance",
      baseroute:"perawatan",
      title:"Daftar Perawatan",
      fields: [
        { key: 'code', label: 'Code', sortable: true },
        { key: 'vehicle.number', label: 'Nomor Polisi', sortable: true },
        { key: 'description', label: 'Deskripsi', sortable: true },
        { key: 'plan_km', label: 'KM Rencana', sortable: true },
        { key: 'created_at', label: 'Tanggal Pengisian Data', sortable: true },
        { key: 'issue.date', label: 'Tanggal Kejadian', sortable: true },
        { key: 'plan_date', label: 'Tanggal Pengajuan', sortable: true },
        { key: 'realisation_date', label: 'Tanggal Perawatan', sortable: true },
        { key: 'realisation_cost', label: 'Biaya Realisasi', sortable: true },
        { key: 'realisation_km', label: 'Km Realisasi', sortable: true },
        { key: 'bill_type', label: 'Jenis Tagihan', sortable: true },
        { key: 'status', label: 'Status', sortable: true },
        { key: 'is_rutin', label: 'Jenis Perawatan' }
      ],
      dataStatus:{
        draft:{color:'info', value:'Rencana Perawatan'},
        request:{color:'info', value:'Butuh Approval Vendor'},
        plan:{color:'info', value:'Belum Ditangani Bengkel'},
        approved:{color:'primary', value:'Dijadwalkan'},
        process:{color:'primary', value:'Penanganan Bengkel'},
        done:{color:'primary', value:'Selesai Penanganan'},
        document:{color:'primary', value:'Kelengkapan Dokumen'},
        finish:{color:'success', value:'Selesai Perawatan'},
        reject:{color:'danger', value:'Perawatan Dibatalkan'}
      },
      bill_type:{
        user:'Haleyora',
        vendor:'Vendor',
        asuransi:'Asuransi'
      },
      showFilter : false,
      filter :[],
      status:null,
      custom_filter : true,
      isDetail : true,
      renderComp : true,
      jobTitle: {
        settings: [],
      },
      userType:null,
      user:null,
      is_rutin:null
    }
  },
  created() {
      if (this.$store.state.profile.info) {
        this.userType =  this.$store.state.profile.info.contact_type.split('\\').pop().toLowerCase()
        this.user = this.$store.state.profile.info.contact
      }
      this.dataurl = this.userType == 'vendor' ? "/maintenance?contact="+this.user.id : "/maintenance"
  },
  mounted() {
      this.fetchData();
  },
  methods:{
    fetchData(){
        this.$http.get('auth/me').then( res => {
          for (let setting of res.data.contact.job_title.settings)
          {
            if (setting.approval && setting.module){
              this.jobTitle.settings.push(setting.module.code);
            }
          }
        })
    },
    show(){
      // this.filter['filter[active]']=this.active
      // this.filter['filter[status_maintenance]']=this.status
      this.$refs.basetable.fetchData()
    },
    getFilter(){
      this.filter['status_maintenance']=this.status
      this.filter['is_rutin']=this.is_rutin
      this.renderComp = false
      this.$nextTick().then(() => {
          this.renderComp = true
      })
    },
    changeSelectStatus(status){
      // if(status != null){
      //     this.filter['status_maintenance'] = status
      // }
    },
    reset(){
      this.status = null
      this.is_rutin = null
      this.filter=[]
      this.renderComp = false
        this.$nextTick().then(() => {
            this.renderComp = true
        })
    },
    getStatus(data){
      var status = data.status
      if(data.status_repair == 'done' && status == 'done'){
        status = 'finish'
      }else if(data.bill_type && "user,asuransi".includes(data.bill_type) && status == 'approved'){
        status = 'document'
      }else if('process,done,plan'.includes(data.status_repair) && 'approved,done'.includes(status)){
        status = data.status_repair
      }else if(status == 'plan'){
        status = 'draft'
      }
      return status
    }
  }
}
</script>
<style lang="">

</style>
